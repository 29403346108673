import React from 'react';
import './App.css';
import Footer from './Footer.js';

function Other() {
  return (
    <>
    <div className ='other'>
    <h1>Coming Soon!</h1>
    </div>
    <Footer />
    </>
  );
}

export default Other;